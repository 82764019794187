.container {
  max-width: 1100px;
  margin: auto;
}

.body {
  background: #ccc;
  overflow: hidden;
}

.imgContainer {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 250px;
  height: 250px;
  overflow: hidden;
  border-radius: 50%;
}

.myImg {
  width: 100%;
  height: 100%;
}

.quoteContainer {
  margin-left: auto;
  margin-right: auto;
  width: 650px;
  height: 650px;

  overflow: hidden;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  font-size: xx-large;
  quotes: "\201C""\201D""\2018""\2019";
  color: #000f02;
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote:after {
  color: #ccc;
  content: close-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.35em;
  vertical-align: -0.4em;
}
blockquote span {
  /* display: inline; */
  font-size: 18px;
  color: #6b0096;
  font-weight: bolder;
}

/* #3299 */
.quoteContainer p {
  text-align: center;
  margin-top: -2rem;
  font-size: large;
  font-weight: bold;
  color: #6b0096;
}
.links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

a {
  text-decoration: none;
  color: #fff;
  width: auto;
  padding: 10px;
  margin-left: 10px;
  background: #6b0096;

  text-align: center;
  border-radius: 12px;

  outline: none;
  cursor: pointer;
}

a span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

a span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  font-size: large;
}
a:hover span {
  padding-right: 25px;
}
a:hover span:after {
  opacity: 1;
  right: 0;
}

.linkBlog {
  margin-top: 20px;
}
.blogLink {
  margin-left: 50%;
}
